import 'bootstrap';
import '../stylesheets/application';
import React from 'react';
import ReactDOM from 'react-dom';
import LandingPage from '../components/LandingPage';

document.addEventListener('DOMContentLoaded', () => {
  const homeElement = document.getElementById('landing-page');
  if (homeElement) {
    ReactDOM.render(<LandingPage />, homeElement);
  }
});
