import React from 'react';

const LandingPage = () => {
  return (
    <main role="main" className="inner cover">
      <h1 className="cover-heading">Get to the Essence.</h1>
      <p className="lead">
        Know your consumer at a scale and depth only possible through AI.
      </p>
      <p className="lead">
        <a href="/about_us" className="btn btn-lg btn-secondary">Discover More</a>
      </p>
    </main>
  );
};

export default LandingPage;
